import * as React from "react";
import Keycloak from 'keycloak-js';
import Api from "../../api";
import { Event, SowingEventData, WastageEventData, CultivationAction, Location, SeedBatch, WastageReason, Facility, TransferEventData, PerformedActionEventData, LoadEventData, PackingEventData, PackingContainerType, PackingType, EventType, Batch, PeatBatch, GritBatch } from "../../generated/client";
import { Navigate } from 'react-router-dom';
import strings from "../../localization/strings";
import { DateTimeInput } from 'semantic-ui-calendar-react';
import * as actions from "../../actions";
import { StoreState } from "../../types/index";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import {
  Grid,
  Button,
  Loader,
  Form,
  Message,
  Confirm,
  TextAreaProps,
  DropdownProps,
  InputOnChangeData,
} from "semantic-ui-react";
import LocalizedUtils from "../../localization/localizedutils";
import moment from "moment";
import { ErrorMessage } from "../../types";
import { FormContainer } from "../FormContainer";

/**
 * Interface representing component properties
 */
interface Props {
  keycloak?: Keycloak;
  eventId: string;
  facility: Facility;
  onError: (error: ErrorMessage | undefined) => void;
}

/**
 * Interface representing component state
 */
interface State {
  open: boolean;
  loading: boolean;
  saving: boolean;
  messageVisible: boolean;
  redirect: boolean;
  event?: Event;
  performedCultivationActions?: CultivationAction[];
  seedBatches?: SeedBatch[];
  locations: Location[];
  wastageReasons?: WastageReason[];
  peatBatches: PeatBatch[];
  gritBatches: GritBatch[];
  batches?: Batch[];
}

/**
 * React component for edit event view
 */
class EditEvent extends React.Component<Props, State> {

  /**
   * Constructor
   *
   * @param props component props
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      redirect: false,
      saving: false,
      messageVisible: false,
      open: false,
      locations: [],
      peatBatches: [],
      gritBatches: []
    };
  }

  /**
   * Component did mount life-sycle method
   */
  public async componentDidMount() {
    const { keycloak, eventId, onError, facility } = this.props;
    try {
      if (!keycloak) {
        return;
      }

      this.setState({loading: true});
      const eventsService = await Api.getEventsService(keycloak);
      const batchesService = await Api.getBatchesService(keycloak);
      const locationsService = await Api.getLocationsService(keycloak);
      const event = await eventsService.findEvent({
        eventId: eventId,
        facility: facility
      });
      const batches = await batchesService.listBatches({ facility: facility });
      this.setState({
        event,
        batches,
        loading: false
      });
      const locations = await locationsService.listLocations({ facility: facility });
      this.setState({
        locations: locations,
        batches: batches
      });
    } catch (e: any) {
      Api.handleApiError(e, onError);
    }
  }

  /**
   * Render edit product view
   */
  public render() {
    if (this.state.loading) {
      return (
        <Grid style={{paddingTop: "100px"}} centered>
          <Loader inline active size="medium" />
        </Grid>
      );
    }

    if (this.state.redirect) {
      return <Navigate replace={true} to="/events"/>;
    }

    if (!this.state.event) {
      return null;
    }

    const { event, batches } = this.state;

    const infoText = strings.labelBatchNumber + ": " + batches?.find(batch => batch.id === event.batchId)?.batchNumber + " - " + LocalizedUtils.getLocalizedEventType(event.type as EventType);

    return (
      <Grid>
        <Grid.Row className="content-page-header-row">
          <Grid.Column width={6}>
            <h2>{strings.editEventHeader}</h2>
            <p>{infoText}</p>
          </Grid.Column>
          <Grid.Column width={3} floated="right">
            <Button className="danger-button" onClick={() => this.setState({open:true})}>{strings.delete}</Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <FormContainer>
              <Form.Field required>
                <label>{strings.labelStartTime}</label>
                <DateTimeInput localization="fi-FI" dateTimeFormat="DD.MM.YYYY HH:mm" onChange={this.handleTimeChange} name="startTime" value={moment(event.startTime).format("DD.MM.YYYY HH:mm")} />
              </Form.Field>
              <Form.Field>
                <label>{strings.labelEndTime}</label>
                <DateTimeInput localization="fi-FI" dateTimeFormat="DD.MM.YYYY HH:mm" onChange={this.handleTimeChange} name="endTime" value={moment(event.endTime).format("DD.MM.YYYY HH:mm")} />
              </Form.Field>
              {this.renderEventDataForm(event)}
              <Form.TextArea label={strings.labelAdditionalInformation} onChange={this.handleBaseChange} name="additionalInformation" value={event.additionalInformation} />
              <Message
                success
                visible={this.state.messageVisible}
                header={strings.savedSuccessfully}
              />
              <Button
                className="submit-button"
                onClick={this.handleSubmit}
                type='submit'
                loading={this.state.saving}
              >
                {strings.save}
              </Button>
              <Button onClick={() => this.setState({redirect: true})}>
                {strings.goBack}
              </Button>
            </FormContainer>
          </Grid.Column>
        </Grid.Row>
        <Confirm open={this.state.open} size={"mini"} content={strings.deleteEventConfirmText} onCancel={()=>this.setState({open:false})} onConfirm={this.handleDelete} />
      </Grid>
    );
  }

    /**
   * Handle value change
   *
   * @param event event
   */
  private handleTimeChange = (e: any, { name, value }: DropdownProps | TextAreaProps) => {
    const eventData: any = this.state.event;
    if (!eventData) {
      return;
    }

    eventData[name] = moment(value as any, "DD.MM.YYYY HH:mm").toDate();
    this.setState({ event: eventData });
  }

  /**
   * Handle value change
   *
   * @param event event
   */
  private handleBaseChange = (e: any, { name, value }: DropdownProps | TextAreaProps) => {
    const event: any = this.state.event;
    if (!event) {
      return;
    }

    event[name] = value;
    this.setState({ event: { ...event } });
  }

  /**
   * Handle value change
   *
   * @param event event
   */
  private handleDataChange = (e: any, { name, value }: DropdownProps | InputOnChangeData | TextAreaProps) => {
    const eventData: any = this.state.event;
    if (!eventData) {
      return;
    }

    eventData.data[name] = value;
    this.setState({ event: eventData });
  }

  /**
   * Handle form submit
   */
  private handleSubmit = async () => {
    const { keycloak, onError, facility } = this.props;
    const { event } = this.state;
    try {
      if (!keycloak || !event) {
        return;
      }

      this.setState({saving: true});
      const eventsService = await Api.getEventsService(keycloak);

      await eventsService.updateEvent({
        eventId: event.id!,
        event: event,
        facility: facility
      });
      this.setState({saving: false, messageVisible: true});
      setTimeout(() => {
        this.setState({messageVisible: false});
      }, 3000);
    } catch (e: any) {
      Api.handleApiError(e, onError);
    }
  }

  /**
   * Handle product delete
   */
  private handleDelete = async () => {
    const { keycloak, onError, facility } = this.props;
    const { event } = this.state;
    try {
      if (!keycloak || !event) {
        return;
      }

      const eventsService = await Api.getEventsService(keycloak);
      const id = event.id || "";

      await eventsService.deleteEvent({
        eventId: id,
        facility: facility
      });

      this.setState({redirect: true});
    } catch (e: any) {
      Api.handleApiError(e, onError);
    }
  }

  /**
   * Renders form suitable for specific event type
   *
   * @param event event
   */
  private renderEventDataForm = (event: Partial<Event>) => {
    switch (event.type) {
      case "TRANSFER":
        return this.renderTransferDataForm(event.data as TransferEventData);
      case "SOWING":
        return this.renderSowingDataForm(event.data as SowingEventData);
      case "WASTAGE":
        return this.renderWastageDataForm(event.data as WastageEventData);
      case "PERFORMED_ACTION":
        return this.renderPerformedActionDataForm(event.data as PerformedActionEventData);
      case "LOAD":
        return this.renderLoadDataForm(event.data as LoadEventData);
      case "PACKING":
        return this.renderPackingDataForm(event.data as PackingEventData);
      default:
        return null;
    }
  }

  /**
   * Renders trasfer event form
   *
   * @param data transfer event data
   */
  private renderTransferDataForm = (data: TransferEventData) => {
    return (
      <React.Fragment>
        <Form.Select
          required label={strings.sourceLocation} name="sourceLocationId" type="sourceLocationId" options={this.getLocationOptions()} value={data.sourceLocationId} onChange={this.handleDataChange} />
        <Form.Select
          required label={strings.targetLocation} name="targetLocationId" type="targetLocationId" options={this.getLocationOptions()} value={data.targetLocationId} onChange={this.handleDataChange} />
        <Form.Input  required label={strings.amount} name="amount" type="number" value={data.amount} onChange={this.handleDataChange} />
      </React.Fragment>
    );
  }

  /**
   * Renders performed action event form
   *
   * @param data performed action event data
   */
  private renderPerformedActionDataForm = (data: PerformedActionEventData) => {
    if (!this.state.performedCultivationActions) {
      this.loadPerformedActionData().catch((e) => {
        Api.handleApiError(e, this.props.onError);

      });

      return;
    }
    const performedActionOptions = (this.state.performedCultivationActions ?? []).map((action) => {
      return {
        key: action.id,
        value: action.id,
        text: LocalizedUtils.getLocalizedValue(action.name)
      };
    });

    return (
      <React.Fragment>
        <Form.Select required label={strings.labelPerformedCultivationActions} options={ performedActionOptions } name="cultivationActionId" value={ data.cultivationActionId} onChange={ this.handleDataChange }/>
        <Form.Input required label={strings.labelAmount} name="amount" type="number" value={data.amount} onChange={this.handleDataChange} />
        <Form.Input required label={strings.labelValue} name="value" type="number" value={data.value} onChange={this.handleDataChange} />
        <Form.TextArea required label={strings.labelActionAdditionalInformation} onChange={this.handleDataChange} name="notes" value={data.notes} />
      </React.Fragment>
    );
  }

  /**
   * Renders loading event form
   *
   * @param data loading event data
   */
  private renderLoadDataForm = (data: LoadEventData) => {
    return (
      <React.Fragment>
        <Form.Select label={strings.targetLocation} name="sourceLocationId" options={this.getLocationOptions()} value={data.sourceLocationId} onChange={this.handleDataChange} />
        <Form.Input required label={strings.labelDriver} name="driver" type="text" value={data.driver} onChange={this.handleDataChange} />
        <Form.Input required label={strings.labelAmount} name="amount" type="number" value={data.amount} onChange={this.handleDataChange} />
      </React.Fragment>
    );
  }

  /**
   * Renders packing event form
   *
   * @param data packing event data
   */
  private renderPackingDataForm = (data: PackingEventData) => {
    const containerTypeOptions = Object.values(PackingContainerType).map((containerType) => {
      return {
        key: containerType,
        value: containerType,
        text: LocalizedUtils.getLocalizedContainerType(containerType)
      };
    });

    const packingTypeOptions = Object.values(PackingType).map((packingType) => {
      return {
        key: packingType,
        value: packingType,
        text: LocalizedUtils.getLocalizedPackingType(packingType)
      };
    });

    return (
      <React.Fragment>
        <Form.Select required label={strings.sourceLocation} name="sourceLocationId" options={this.getLocationOptions()} value={data.sourceLocationId} onChange={this.handleDataChange} />
        <Form.Select required label={strings.targetLocation} name="targetLocationId" options={this.getLocationOptions()} value={data.targetLocationId} onChange={this.handleDataChange} />
        <Form.Input required label={strings.labelLargeTrayAmount} name="largeTrayAmount" type="number" value={data.largeTrayAmount} onChange={this.handleDataChange} />
        <Form.Select required label={strings.labelContainerType} name="containerType" options={containerTypeOptions} value={data.containerType} onChange={this.handleDataChange} />
        <Form.Input required label={strings.labelBoxAmount} name="boxAmount" type="number" value={data.boxAmount} onChange={this.handleDataChange} />
        <Form.Select required label={strings.labelPackingType} name="packingType" options={packingTypeOptions} value={data.packingType} onChange={this.handleDataChange} />
        <Form.Input required label={strings.labelPersonCount} name="personCount" type="number" value={data.personCount} onChange={this.handleDataChange} />
      </React.Fragment>
    );
  }

  /**
   * Renders sowing event form
   *
   * @param data sowing event data
   */
  private renderSowingDataForm = (data: SowingEventData) => {
    if (!this.state.seedBatches) {
      this.loadSowingData().catch((e) => {
        Api.handleApiError(e, this.props.onError);
      });

      return;
    }
    const seedBatchOptions = this.state.seedBatches.map((seedBatch) => {
      return {
        key: seedBatch.id,
        value: seedBatch.id,
        text: seedBatch.svCode
      };
    });
    const gritBatchOptions = this.state.gritBatches.map((batch) => {
      return {
        key: batch.id,
        value: batch.id,
        text: batch.batchNumber
      };
    });
    const peatBatchOptions = this.state.peatBatches.map((batch) => {
      return {
        key: batch.id,
        value: batch.id,
        text: batch.batchNumber
      };
    });

    return (
      <React.Fragment>
        <Form.Input required label={strings.cellAmount} name="amount" type="number" value={data.amount} onChange={this.handleDataChange} />
        <Form.Select required label={strings.labelSeedBatch} name="seedBatchId" options={seedBatchOptions} value={data.seedBatchId} onChange={this.handleDataChange} />
        <Form.Input required label={strings.amount} name="seedAmount" type="number" value={data.seedAmount} onChange={this.handleDataChange} />
        <Form.Input required label={strings.timber} name="woodType" type="text" value={data.woodType} onChange={this.handleDataChange} />
        <Form.Select required label={strings.location} name="locationId" options={this.getLocationOptions()} value={data.locationId} onChange={this.handleDataChange} />
        <Form.Select required label={strings.gritBatches} name="gritBatchId" options={gritBatchOptions} value={data.gritBatchId} onChange={this.handleDataChange} />
        <Form.Input required label={strings.amount} name="gritAmount" type="number" value={data.gritAmount} onChange={this.handleDataChange} />
        <Form.Select required label={strings.peatBatches} name="peatBatchId" options={peatBatchOptions} value={data.peatBatchId} onChange={this.handleDataChange} />
        <Form.Input required label={strings.amount} name="peatAmount" type="number" value={data.peatAmount} onChange={this.handleDataChange} />
        <Form.Input required label={strings.labelPersonCount} name="amountOfPersons" type="number" value={data.amountOfPersons} onChange={this.handleDataChange} />
      </React.Fragment>
    );
  }

  /**
   * Renders wastage event form
   *
   * @param data wastage event data
   */
  private renderWastageDataForm = (data: WastageEventData) => {
    if (!this.state.wastageReasons) {
      this.loadWastageData().catch((e) => {
        Api.handleApiError(e, this.props.onError);
      });

      return;
    }

    const wastageReasonOptions = this.state.wastageReasons.map((wastageReason) => {
      return {
        key: wastageReason.id,
        value: wastageReason.id,
        text: LocalizedUtils.getLocalizedValue(wastageReason.reason)
      };
    });

    const phaseOptions = Object.values(EventType).map((phase) => {
      return {
        key: phase,
        value: phase,
        text: LocalizedUtils.getLocalizedEventType(phase as EventType)
      };
    })

    return (
      <React.Fragment>
        <Form.Select required label={strings.labelWastageReason} name="reasonId" options={wastageReasonOptions} value={data.reasonId} onChange={this.handleDataChange} />
        <Form.Select required label={strings.labelPhase} name="phase" options={phaseOptions} value={data.phase} onChange={this.handleDataChange} />
        <Form.Select label={strings.labelLocation} name="locationId" options={this.getLocationOptions()} value={data.locationId} onChange={this.handleDataChange} />
        <Form.Input required label={strings.labelAmount} name="amount" type="number" value={data.amount} onChange={this.handleDataChange} />
      </React.Fragment>
    );
  }

  /**
   * Loads data required for sowing event
   */
  private loadSowingData = async () => {
    const { keycloak, facility } = this.props;
    if (!keycloak) {
      return;
    }

    this.setState({loading: true});
    const [seedBatchesService, locationsService, gritBatchService, peatBatchService] = await Promise.all([
      Api.getSeedBatchesService(keycloak),
      Api.getLocationsService(keycloak),
      Api.getGritBatchesService(keycloak),
      Api.getPeatBatchesService(keycloak)
    ]);

    const [seedBatches, locations, peatBatches, gritBatches] = await Promise.all([
      seedBatchesService.listSeedBatches({ facility: facility }),
      locationsService.listLocations({ facility: facility }),
      peatBatchService.listPeatBatches({ facility: facility }),
      gritBatchService.listGritBatches({ facility: facility })
    ]);

    this.setState({
      loading: false,
      seedBatches: seedBatches,
      locations: locations,
      peatBatches: peatBatches,
      gritBatches: gritBatches
    });
  }

  /**
   * Loads data required for wastage event
   */
  private loadWastageData = async () => {
    const { keycloak, facility } = this.props;
    if (!keycloak) {
      return;
    }

    this.setState({loading: true});
    const [wastageReasonsService, locationsService] = await Promise.all([
      Api.getWastageReasonsService(keycloak),
      Api.getLocationsService(keycloak)
    ]);

    const [wastageReasons, locations] = await Promise.all([
      wastageReasonsService.listWastageReasons({ facility: facility }),
      locationsService.listLocations({ facility: facility })
    ]);

    this.setState({
      loading: false,
      wastageReasons: wastageReasons,
      locations: locations
    });
  }

  /**
   * Loads data required for performed action event
   */
  private loadPerformedActionData = async () => {
    const { keycloak, facility } = this.props;
    if (!keycloak) {
      return;
    }

    this.setState({loading: true});
    const [cultivationActionsService] = await Promise.all([
      Api.getPerformedCultivationActionsService(keycloak)
    ]);

    const [cultivationActions] = await Promise.all([
      cultivationActionsService.listCultivationActions({ facility: facility })
    ]);

    this.setState({
      loading: false,
      performedCultivationActions: cultivationActions
    });
  }

  /**
   * Loads data required for
   */

  /**
   * Get location options
   *
   * @returns location options
   */
  private getLocationOptions = () => {
    return this.state.locations.map((location) => {
      return {
        key: location.id,
        value: location.id,
        text: location.name
      };
    });
  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
export function mapStateToProps(state: StoreState) {
  return {
    facility: state.facility
  };
}

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
export function mapDispatchToProps(dispatch: Dispatch<actions.AppAction>) {
  return {
     onError: (error: ErrorMessage | undefined) => dispatch(actions.onErrorOccurred(error))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditEvent);