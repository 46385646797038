import * as React from "react";
import Keycloak from 'keycloak-js';
import * as actions from "../../actions";
import { ErrorMessage, StoreState } from "../../types";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import Api from "../../api";
import { SeedBatch, Facility, TreeSpecies } from "../../generated/client";
import { Navigate } from 'react-router-dom';
import strings from "../../localization/strings";
import { FormContainer } from "../FormContainer";

import {
  Grid,
  Button,
  Input,
  DropdownProps,
  Form,
  InputOnChangeData
} from "semantic-ui-react";
import LocalizedUtils from "../../localization/localizedutils";

interface Props {
  keycloak?: Keycloak;
  seedBatch?: SeedBatch;
  facility: Facility;
  onSeedBatchCreated?: (seedBatch: SeedBatch) => void;
  onTreeSpeciesFound?: (treeSpecies: TreeSpecies[]) => void;
  onError: (error: ErrorMessage | undefined) => void;
}

interface State {
  treeSpeciesId: string;
  redirect: boolean;
  treeSpecies: TreeSpecies[];
  svCode: string;
  thousandSeedWeight: number;
  reportedGermination: number;
  testedGermination: number;
  baseCertificateNumber: string;
  quantityOfUnits?: number;
  quantityOfKilos?: number;
  priceForUnit?: number;
  priceForKilo?: number;
  purity: number;
  containerNumber: string;
  seedIdentifier: string;
  baseMaterialType: string;
  sourceArea: string;
  forestryClass: string
}

class CreateSeedBatch extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      redirect: false,
      treeSpeciesId: '',
      treeSpecies: [],
      svCode: '',
      thousandSeedWeight: 1,
      reportedGermination: 1,
      testedGermination: 1,
      baseCertificateNumber: '',
      purity: 1,
      containerNumber: '',
      seedIdentifier: '',
      baseMaterialType: '',
      sourceArea: '',
      forestryClass: ''
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /**
   * Component did mount life-sycle method
   */
  public async componentDidMount() {
    const { keycloak, onError, facility, onTreeSpeciesFound } = this.props;
    try {
      if (!keycloak) {
        return;
      }

      const treeSpeciesService = await Api.getTreeSpeciesService(keycloak);
      const treeSpecies = await treeSpeciesService.listTreeSpecies({ facility: facility });
      onTreeSpeciesFound && onTreeSpeciesFound(treeSpecies);
      this.setState({ treeSpecies });
    } catch (e: any) {
      Api.handleApiError(e, onError);
    }
  }

  /**
   * Handle select change
   *
   * @param e event
   * @param {value} value
   */
  onSelectChange = (e: any, { value }: DropdownProps) => {
    this.setState({treeSpeciesId: value as string});
  }

  /**
   * Handle form submit
   */
  private async handleSubmit() {
    const { keycloak, facility, onError } = this.props;
    const {
      treeSpeciesId,
      treeSpecies,
      svCode,
      thousandSeedWeight,
      reportedGermination,
      testedGermination,
      baseCertificateNumber,
      quantityOfUnits,
      quantityOfKilos,
      priceForUnit,
      priceForKilo,
      purity,
      containerNumber,
      seedIdentifier,
      baseMaterialType,
      sourceArea,
      forestryClass
    } = this.state;
    try {
      if (!keycloak) {
        return;
      }

      if (quantityOfKilos === undefined && quantityOfUnits === undefined) {
        onError({
          message: strings.errorSeedBatchMissingQuantitiesMessage,
          title: strings.errorSeedBatchMissingQuantitiesMessage,
        })
        return;
      }

      const seedBatchObject = {
        treeSpeciesId: treeSpeciesId,
        treeSpecies: treeSpecies,
        svCode: svCode,
        thousandSeedWeight: thousandSeedWeight,
        reportedGermination: reportedGermination,
        testedGermination: testedGermination,
        baseCertificateNumber: baseCertificateNumber,
        quantityOfUnits: quantityOfUnits,
        quantityOfKilos: quantityOfKilos,
        priceForUnit: priceForUnit,
        priceForKilo: priceForKilo,
        purity: purity,
        containerNumber: containerNumber,
        seedIdentifier: seedIdentifier,
        baseMaterialType: baseMaterialType,
        sourceArea: sourceArea,
        forestryClass: forestryClass,
      };

      const seedBatchService = await Api.getSeedBatchesService(keycloak);
      await seedBatchService.createSeedBatch({
        seedBatch: seedBatchObject,
        facility: facility
      });

      this.setState({redirect: true});
    } catch (e: any) {
      Api.handleApiError(e, onError);
    }
  }

  /**
   * Render create seed batch view
   */
  render() {
    if (this.state.redirect) {
      return <Navigate to="/seedBatches" replace={true} />;
    }

    const {
      treeSpecies,
      svCode,
      thousandSeedWeight,
      reportedGermination,
      testedGermination,
      baseCertificateNumber,
      quantityOfUnits,
      quantityOfKilos,
      priceForUnit,
      priceForKilo,
      purity,
      containerNumber,
      seedIdentifier,
      baseMaterialType,
      sourceArea,
      forestryClass
    } = this.state;

    const treeSpeciesOptions = (treeSpecies || []).map((tree) => {
      return {
        key: tree.id,
        text: LocalizedUtils.getLocalizedValue(tree?.name),
        value: tree.id
      };
    });

    return (
      <Grid>
        <Grid.Row className="content-page-header-row">
          <Grid.Column width={8}>
            <h2>{strings.newSeedBatch}</h2>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <FormContainer>
            <Form.Select
                fluid
                required
                value={this.state.treeSpeciesId}
                label={strings.treeName}
                options={treeSpeciesOptions}
                placeholder={strings.treeName}
                onChange={this.onSelectChange}
              />
              <Form.Field required>
                <label>{strings.svCode}</label>
                <Input
                  value={svCode}
                  onChange={(e) => this.setState({svCode: e.currentTarget.value})}
                />
              </Form.Field>
              <Form.Field required>
                <label>{strings.thousandSeedWeight}</label>
                <Input
                  type="number"
                  step="0.01"
                  value={thousandSeedWeight}
                  onChange={ this.onThousandSeedWeightChange }
                />
              </Form.Field>
              <Form.Field required>
                <label>{strings.reportedGermination}</label>
                <Input
                  type="number"
                  step="0.01"
                  value={reportedGermination}
                  onChange={ this.onReportedGerminationChange }
                />
              </Form.Field>
              <Form.Field>
                <label>{strings.testedGermination}</label>
                <Input
                  type="number"
                  step="0.01"
                  value={testedGermination}
                  onChange={ this.onTestedGerminationChange }
                />
              </Form.Field>
              <Form.Field required>
                <label>{strings.baseCertificateNumber}</label>
                <Input
                  value={baseCertificateNumber}
                  onChange={(e) => this.setState({baseCertificateNumber: e.currentTarget.value})}
                />
              </Form.Field>
              <Form.Field>
                <label>{strings.quantityOfKilos}</label>
                <Input
                  type="number"
                  step="0.01"
                  value={quantityOfKilos}
                  onChange={ this.onQuantityOfKilosChange }
                />
              </Form.Field>
              <Form.Field>
                <label>{strings.quantityOfUnits}</label>
                <Input
                  type="number"
                  step="1"
                  value={quantityOfUnits}
                  onChange={ this.onQuantityOfUnitsChange }
                />
              </Form.Field>
              <Form.Field>
                <label>{strings.priceForKilo}</label>
                <Input
                  type="number"
                  step="0.01"
                  value={priceForKilo}
                  onChange={ this.onPriceForKiloChange }
                />
              </Form.Field>
              <Form.Field>
                <label>{strings.priceForUnit}</label>
                <Input
                  type="number"
                  step="0.01"
                  value={priceForUnit}
                  onChange={ this.onPriceForUnitChange }
                />
              </Form.Field>
              <Form.Field>
                <label>{strings.purity}</label>
                <Input
                  type="number"
                  step="0.01"
                  value={purity}
                  onChange={ this.onPurityChange }
                />
              </Form.Field>
              <Form.Field>
                <label>{strings.containerNumber}</label>
                <Input
                  value={containerNumber}
                  onChange={(e) => this.setState({containerNumber: e.currentTarget.value})}
                />
              </Form.Field>
              <Form.Field>
                <label>{strings.seedIdentifier}</label>
                <Input
                  value={seedIdentifier}
                  onChange={(e) => this.setState({seedIdentifier: e.currentTarget.value})}
                />
              </Form.Field>
              <Form.Field>
                <label>{strings.baseMaterialType}</label>
                <Input
                  value={baseMaterialType}
                  onChange={(e) => this.setState({baseMaterialType: e.currentTarget.value})}
                />
              </Form.Field>
              <Form.Field>
                <label>{strings.sourceArea}</label>
                <Input
                  value={sourceArea}
                  onChange={(e) => this.setState({sourceArea: e.currentTarget.value})}
                />
              </Form.Field>
              <Form.Field>
                <label>{strings.forestryClass}</label>
                <Input
                  value={forestryClass}
                  onChange={(e) => this.setState({forestryClass: e.currentTarget.value})}
                />
              </Form.Field>
              <Button className="submit-button" onClick={this.handleSubmit} type='submit'>{strings.save}</Button>
            </FormContainer>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

    /**
   * Handles changing onThousandSeedWeightChange
   */
    private onThousandSeedWeightChange = async (e: any, { value }: InputOnChangeData) => {
      this.setState({ thousandSeedWeight: Number.parseFloat(value) });
    }


    /**
   * Handles changing onThousandSeedWeightChange
   */
    private onReportedGerminationChange = async (e: any, { value }: InputOnChangeData) => {
      this.setState({ reportedGermination: Number.parseFloat(value) });
    }


    /**
   * Handles changing onThousandSeedWeightChange
   */
    private onTestedGerminationChange = async (e: any, { value }: InputOnChangeData) => {
      this.setState({ testedGermination: Number.parseFloat(value) });
    }


    /**
   * Handles changing onQuantityOfUnitsChange
   */
    private onQuantityOfUnitsChange = async (e: any, { value }: InputOnChangeData) => {
      this.setState({ quantityOfUnits: Number.parseInt(value) });
    }

    /**
   * Handles changing onQuantityOfKilosChange
   */
    private onQuantityOfKilosChange = async (e: any, { value }: InputOnChangeData) => {
      this.setState({ quantityOfKilos: Number.parseFloat(value) });
    }

    /**
   * Handles changing onPriceForUnitChange
   */
    private onPriceForUnitChange = async (e: any, { value }: InputOnChangeData) => {
      this.setState({ priceForUnit: Number.parseFloat(value) });
    }

    /**
   * Handles changing onPriceForKiloChange
   */
    private onPriceForKiloChange = async (e: any, { value }: InputOnChangeData) => {
      this.setState({ priceForKilo: Number.parseFloat(value) });
    }

    /**
   * Handles changing onThousandSeedWeightChange
   */
    private onPurityChange = async (e: any, { value }: InputOnChangeData) => {
      this.setState({ purity: Number.parseFloat(value) });
    }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
export function mapStateToProps(state: StoreState) {
  return {
    seedBatches: state.seedBatches,
    seedBatch: state.seedBatch,
    treeSpecies: state.treeSpecies,
    facility: state.facility
  };
}

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
export function mapDispatchToProps(dispatch: Dispatch<actions.AppAction>) {
  return {
    onSeedBatchCreated: (seedBatch: SeedBatch) => dispatch(actions.seedBatchCreated(seedBatch)),
    onTreeSpeciesFound: (treeSpecies: TreeSpecies[]) => dispatch(actions.treeSpeciesFound(treeSpecies)),
    onError: (error: ErrorMessage | undefined) => dispatch(actions.onErrorOccurred(error))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSeedBatch);