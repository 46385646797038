import { EventType, LocalizedValue, LocationType, PackingContainerType, PackingType } from "../generated/client";
import { BatchType } from "../types";
import strings from "./strings";

/**
 * Helper class for using localized values
 */
export default class LocalizedUtils {
  /**
   * Returns localized value
   *
   * @param entry localized entry
   * @param locale Locale
   * @returns value
   */
  public static getLocalizedValue(entry?: LocalizedValue[], localeParam?: string): string {
    if (!entry) {
      return "";
    }

    if (!localeParam) {
      localeParam = strings.getLanguage().slice(0, 2);
    }

    for (let i = 0; i < entry.length; i++) {
      if (localeParam === entry[i].language) {
        return entry[i].value;
      }
    }

    if (localeParam !== "fi") {
      return this.getLocalizedValue(entry, "fi");
    }

    return "";
  }

  /**
   * Returns localized even type
   *
   * @param eventType event type
   */
  public static getLocalizedEventType = (eventType: EventType) => {
    const eventTypeMap = {
      [EventType.Transfer]: strings.phaseTRANSFER,
      [EventType.Sowing]: strings.phaseSOWING,
      [EventType.Wastage]: strings.phaseWASTAGE,
      [EventType.PerformedAction]: strings.phasePerformedACTION,
      [EventType.Load]: strings.phaseLOAD,
      [EventType.Packing]: strings.phasePACKING
    };

    return eventTypeMap[eventType];
  };

  /**
   * Get localized packing container type
   *
   * @param containerType container type
   * @returns localized container type
   */
  public static getLocalizedContainerType = (containerType: string): string => {
    const containerTypeMap: { [key: string]: string } = {
      [PackingContainerType.Bag]: strings.containerBag,
      [PackingContainerType.Cardboard]: strings.containerCardboard,
      [PackingContainerType.Plastic]: strings.containerPlastic
    };

    return containerTypeMap[containerType];
  }

  /**
   * Get localized packing type
   *
   * @param packingType packing type
   * @returns localized packing type
   */
  public static getLocalizedPackingType = (packingType: string): string => {
    const packingTypeMap: { [key: string]: string } = {
      [PackingType.ByHand]: strings.byHand,
      [PackingType.Machine]: strings.machine
    };

    return packingTypeMap[packingType];
  }

  /**
   * Returns localized batch type
   *
   * @param batchType batch type
   * @returns localized batch type
   */
  public static getLocalizedBatchType = (batchType: string): string => {
    const batchTypeMap: { [key: string]: string } = {
      [BatchType.PEAT]: strings.peat,
      [BatchType.GRIT]: strings.grit
    };

    return batchTypeMap[batchType];
  };

  /**
   * Get localized location type
   *
   * @param locationType location type
   */
  public static getLocalizedLocationType = (locationType: LocationType) => {
    const locationTypeMap = {
      [LocationType.PlasticRoom]: strings.plasticRoom,
      [LocationType.Ramp]: strings.ramp,
      [LocationType.Freezer]: strings.freezer,
      [LocationType.Melting]: strings.melting
    };

    return locationTypeMap[locationType];
  }

  /**
   * Get facility specific overrides for locale
   *
   * @param locale string
   * @param facility string
   * @returns locales object with facility overrides
   */
  public static getFacilityOverrides = (locale: string, facility: string) => {
    const baseLocale = require(`./${locale}.json`);
    const facilityOverrides = require(`./${locale}.${facility}.json`);

    return { ...baseLocale, ...facilityOverrides };
  }

}