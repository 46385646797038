import { connect } from "react-redux";
import React from "react";
import { Facility, PeatBatch } from "../../generated/client";
import { ErrorMessage, StoreState } from "../../types";
import Keycloak from 'keycloak-js';
import { Dispatch } from "redux"
import Api from "../../api";
import strings from "../../localization/strings";
import * as actions from "../../actions";
import { Navigate } from "react-router-dom";
import { Button, Confirm, Form, Grid, Input, InputOnChangeData, Loader } from "semantic-ui-react";
import { FormContainer } from "../FormContainer";
import { DateInput } from "semantic-ui-calendar-react";
import moment from "moment";


/**
 * Interface representing component properties
 */
interface Props {
  keycloak?: Keycloak;
  peatBatchId: string;
  peatBatch?: PeatBatch;
  facility: Facility;
  onPeatBatchSelected?: (peatBatch: PeatBatch) => void;
  onPeatBatchDeleted?: (peatBatchId: string) => void;
  onError: (error: ErrorMessage | undefined) => void;
}
/**
 * Interface representing component state
 */
interface State {
  peatBatch?: PeatBatch;
  redirect: boolean;
  saving: boolean;
  messageVisible: boolean;
  open:boolean;
}
/**
 * React component for edit peat batch view
 */
class EditPeatBatch extends React.Component<Props, State> {

  /**
   * Constructor
   *
   * @param props component properties
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      redirect: false,
      peatBatch: undefined,
      saving: false,
      messageVisible: false,
      open:false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  /**
   * Component did mount life-sycle method
   */
  public async componentDidMount() {
    const { keycloak, facility, onError, onPeatBatchSelected } = this.props;
    try {
      if (!keycloak) {
        return;
      }
      const peatBatchesService = await Api.getPeatBatchesService(keycloak);
      const peatBatch = await peatBatchesService.findPeatBatch({
        peatBatchId: this.props.peatBatchId,
        facility: facility
      });
      onPeatBatchSelected && onPeatBatchSelected(peatBatch);
      this.setState({peatBatch: peatBatch});
    } catch (e: any) {
      Api.handleApiError(e, onError);
    }
  }

  /**
   * Handle form submit
   */
  private async handleSubmit() {
    const { keycloak, facility, onError, onPeatBatchSelected } = this.props;
    try {
      if (!keycloak || !this.state.peatBatch) {
        return;
      }
      const peatBatchesService = await Api.getPeatBatchesService(keycloak);
      this.setState({saving: true});
      await peatBatchesService.updatePeatBatch({
        peatBatchId: this.state.peatBatch.id!,
        peatBatch: this.state.peatBatch,
        facility: facility
      });
      onPeatBatchSelected && onPeatBatchSelected(this.state.peatBatch);
      this.setState({saving: false});
      this.setState({messageVisible: true});
      this.setState({redirect: true});
      setTimeout(() => {
        this.setState({messageVisible: false});
      }, 3000);
    } catch (e: any) {
      Api.handleApiError(e, onError);
    }
  }

  /**
   * Handle gritBatch delete
   */
  private async handleDelete() {
    const { keycloak, facility, onError, onPeatBatchDeleted } = this.props;
    try {
      if (!keycloak || !this.state.peatBatch) {
        return;
      }
      const peatBatchService = await Api.getPeatBatchesService(keycloak);
      const id = this.state.peatBatch.id || "";
      await peatBatchService.deletePeatBatch({
        peatBatchId: id,
        facility: facility
      });
      onPeatBatchDeleted && onPeatBatchDeleted(id!);
      this.setState({redirect: true});
    } catch (e: any) {
      Api.handleApiError(e, onError);
    }
  }

  /**
   * Render edit seedBatch view
   */
  public render() {
    if (!this.state.peatBatch) {
      return (
        <Grid style={{paddingTop: "100px"}} centered>
          <Loader inline active size="medium" />
        </Grid>
      );
    }
    if (this.state.redirect) {
      return <Navigate to="/peatGritBatches" replace={true} />;
    }

    const deliveryDateText = moment(this.state.peatBatch?.deliveryDate).format("DD.MM.YYYY");

    return (
      <Grid>
        <Grid.Row className="content-page-header-row">
          <Grid.Column width={6}>
            <h2>{strings.peatBatchHeader}</h2>
          </Grid.Column>
          <Grid.Column width={3} floated="right">
            <Button className="danger-button" onClick={()=>this.setState({open:true})}>{strings.delete}</Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <FormContainer>
              <Form.Field required>
                <label>{strings.labelAmount}</label>
                <Input
                  type="number"
                  step="0.01"
                  value={ this.state.peatBatch?.amount }
                  onChange={ this.onAmountChange }
                />
              </Form.Field>
              <Form.Field required>
                <label>{ strings.batchNumber }</label>
                <Input
                  value={ this.state.peatBatch?.batchNumber }
                  onChange={ this.onBatchNumberChange }
                />
              </Form.Field>
              <Form.Field required>
                <label>{ strings.supplier }</label>
                <Input
                  value={ this.state.peatBatch?.supplier }
                  onChange={ this.onSupplierChange }
                />
              </Form.Field>
              <Form.Field required>
                <label>{ strings.deliveryDate }</label>
                <DateInput
                  dateFormat="DD.MM.YYYY"
                  onChange={ this.onDeliveryDateChange }
                  localStartization="fi-FI"
                  value={ deliveryDateText }
                />
              </Form.Field>
              <Button
              className="submit-button"
              onClick={this.handleSubmit}
              type='submit'
              loading={this.state.saving}
            >
                {strings.save}
            </Button>
            </FormContainer>
          </Grid.Column>
        </Grid.Row>
        <Confirm open={this.state.open} size={"mini"} content={strings.deleteConfirmationText +this.state.peatBatch!.batchNumber } onCancel={()=>this.setState({open:false})} onConfirm={this.handleDelete} />
      </Grid>
    );
  }

  /**
   * Updates batch number
   */
  private onBatchNumberChange = async (e: any, { value }: InputOnChangeData) => {
    this.setState({peatBatch: {...this.state.peatBatch!, batchNumber: value}})
  }

  /**
   * Updates supplier
   */
  private onSupplierChange = async (e: any, { value }: InputOnChangeData) => {
    this.setState({peatBatch: {...this.state.peatBatch!, supplier: value}})
  }

  /**
   * Updates delivery date
   */
  private onDeliveryDateChange = async (e: any, { value }: InputOnChangeData) => {
    const date =  moment(value as any, "DD.MM.YYYY").toDate();
    this.setState({peatBatch: {...this.state.peatBatch!, deliveryDate: date}})
  }

  /**
   * Updates amount
   */
  private onAmountChange = async (e: any, { value }: InputOnChangeData) => {
    this.setState({peatBatch: {...this.state.peatBatch!, amount: Number.parseFloat(value)}})
  }

}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
export function mapStateToProps(state: StoreState) {
  return {
    seedBatches: state.seedBatches,
    seedBatch: state.seedBatch,
    treeSpecies: state.treeSpecies,
    facility: state.facility
  };
}/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
export function mapDispatchToProps(dispatch: Dispatch<actions.AppAction>) {
  return {
    onPeatBatchSelected: (peatBatch: PeatBatch) => dispatch(actions.peatBatchSelected(peatBatch)),
    onPeatBatchDeleted: (peatBatchId: string) => dispatch(actions.peatBatchDeleted(peatBatchId)),
    onError: (error: ErrorMessage | undefined) => dispatch(actions.onErrorOccurred(error))
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditPeatBatch);