import * as React from "react";
import Keycloak from 'keycloak-js';
import * as actions from "../../actions";
import { BatchType, ErrorMessage, StoreState } from "../../types";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import Api from "../../api";
import { SeedBatch, Facility, TreeSpecies, GritBatch, PeatBatch } from "../../generated/client";
import { Navigate } from 'react-router-dom';
import strings from "../../localization/strings";
import { FormContainer } from "../FormContainer";

import {
  Grid,
  Button,
  Input,
  DropdownProps,
  Form,
  InputOnChangeData
} from "semantic-ui-react";
import LocalizedUtils from "../../localization/localizedutils";
import moment from "moment";
import { DateInput } from "semantic-ui-calendar-react";

interface Props {
  keycloak?: Keycloak;
  gritBatch?: GritBatch;
  peatBatch?: PeatBatch;
  facility: Facility;
  onGritBatchCreated?: (gritBatch: GritBatch) => void;
  onPeatBatchCreated?: (peatBatch: PeatBatch) => void;
  onError: (error: ErrorMessage | undefined) => void;
}

interface State {
  peatBatch: PeatBatch,
  gritBatch: GritBatch,
  redirect: boolean;
  batchType: BatchType;
}

class CreatePeatGritBatch extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    var gritBatch: GritBatch = {
      amount: 0,
      batchNumber: '',
      deliveryDate: new Date(),
      supplier: '',
    }
    var peatBatch: PeatBatch = {
      amount: 0,
      batchNumber: '',
      deliveryDate: new Date(),
      supplier: '',
    }
    this.state = {
      redirect: false,
      gritBatch: gritBatch,
      peatBatch: peatBatch,
      batchType: BatchType.GRIT
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /**
   * Component did mount life-sycle method
   */
  public async componentDidMount() {
    const { keycloak, onError } = this.props;
    try {
      if (!keycloak) {
        return;
      }

    } catch (e: any) {
      Api.handleApiError(e, onError);
    }
  }

  /**
   * Handle form submit
   */
  private async handleSubmit() {
    const { keycloak, facility, onError } = this.props;
    try {
      if (!keycloak) {
        return;
      }

      if (this.state.batchType === BatchType.GRIT) {
        const gritBatchService = await Api.getGritBatchesService(keycloak);
        await gritBatchService.createGritBatch({
          gritBatch: this.state.gritBatch,
          facility: facility
        });
      } else if (this.state.batchType === BatchType.PEAT) {
        const peatBatchService = await Api.getPeatBatchesService(keycloak);
        await peatBatchService.createPeatBatch({
          peatBatch: this.state.peatBatch,
          facility: facility
        });
      }

      this.setState({redirect: true});
    } catch (e: any) {
      Api.handleApiError(e, onError);
    }
  }

  /**
   * Render create seed batch view
   */
  render() {
    if (this.state.redirect) {
      return <Navigate to="/peatGritBatches" replace={true} />;
    }

    return (
      <Grid>
        <Grid.Row className="content-page-header-row">
          <Grid.Column width={8}>
            <h2>{strings.newRawMaterial}</h2>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <FormContainer>
            <Form.Select
                required
                value={ this.state.batchType}
                label={ strings.batchType }
                options={ this.renderBatchTypeOptions() }
                onChange={ this.onSelectChange }
              />
              <Form.Field required>
                <label>{ strings.amount }</label>
                <Input
                  type="number"
                  step="0.01"
                  value={ this.getAmount() }
                  onChange={ this.onAmountChange }
                />
              </Form.Field>
              <Form.Field required>
                <label>{ strings.batchNumber }</label>
                <Input
                  value={ this.getBatchNumber() }
                  onChange={ this.onBatchNumberChange }
                />
              </Form.Field>
              <Form.Field required>
                <label>{ strings.supplier }</label>
                <Input
                  value={ this.getSupplier() }
                  onChange={ this.onSupplierChange }
                />
              </Form.Field>
              <Form.Field required>
                <label>{ strings.deliveryDate }</label>
                <DateInput
                  dateFormat="DD.MM.YYYY"
                  onChange={ this.onDeliveryDateChange }
                  localStartization="fi-FI"
                  value={ this.getDeliveryDate() }
                />
              </Form.Field>
              <Button className="submit-button" onClick={this.handleSubmit} type='submit'>{strings.save}</Button>
            </FormContainer>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  /**
   * Returns the amount for peat or grit batch
   * @returns amount
   */
  private getAmount = () => {
    if (this.state.batchType === BatchType.GRIT) {
      return this.state.gritBatch.amount;
    } else if (this.state.batchType === BatchType.PEAT) {
      return this.state.peatBatch.amount;
    }
  }

  /**
   * Returns the batch number for peat or grit batch
   * @returns batch number
   */
  private getBatchNumber = () => {
    if (this.state.batchType === BatchType.GRIT) {
      return this.state.gritBatch.batchNumber;
    } else if (this.state.batchType === BatchType.PEAT) {
      return this.state.peatBatch.batchNumber;
    }
  }

  /**
   * Returns supplier for peat or grit batch
   * @returns supplier
   */
  private getSupplier = () => {
    if (this.state.batchType === BatchType.GRIT) {
      return this.state.gritBatch.supplier;
    } else if (this.state.batchType === BatchType.PEAT) {
      return this.state.peatBatch.supplier;
    }
  }

  /**
   * Returns delivery date for peat or grit batch
   * @returns delivery date
   */
  private getDeliveryDate = () => {
    var date = this.state.batchType === BatchType.GRIT ?
      this.state.gritBatch.deliveryDate:
      this.state.peatBatch.deliveryDate;
    const dateText = date ? moment(date).format("DD.MM.YYYY") : "";

    return dateText;
  }

  /**
   * Sets amount
   */
  private onAmountChange = async (e: any, { value }: InputOnChangeData) => {
    var parsedAmount = Number.parseFloat(value);
    if (this.state.batchType === BatchType.GRIT) {
      this.setState({
        gritBatch: {...this.state.gritBatch, amount: parsedAmount }
      });
    } else if (this.state.batchType === BatchType.PEAT) {
      this.setState({
        peatBatch: {...this.state.peatBatch, amount: parsedAmount }
      });
    }
  }

  /**
   * Sets batch number
   */
  private onBatchNumberChange = async (e: any, { value }: InputOnChangeData) => {
    if (this.state.batchType === BatchType.GRIT) {
      this.setState({
        gritBatch: {...this.state.gritBatch, batchNumber: value }
      });
    } else if (this.state.batchType === BatchType.PEAT) {
      this.setState({
        peatBatch: {...this.state.peatBatch, batchNumber: value }
      });
    }
  }

  /**
   * Sets supplier
   */
  private onSupplierChange = async (e: any, { value }: InputOnChangeData) => {
    if (this.state.batchType === BatchType.GRIT) {
      this.setState({
        gritBatch: {...this.state.gritBatch, supplier: value }
      });
    } else if (this.state.batchType === BatchType.PEAT) {
      this.setState({
        peatBatch: {...this.state.peatBatch, supplier: value }
      });
    }
  }

  /**
   * Sets delivery date
   */
  private onDeliveryDateChange = async (e: any, { value }: InputOnChangeData) => {
    const date =  moment(value as any, "DD.MM.YYYY").toDate();
    if (this.state.batchType === BatchType.GRIT) {
      this.setState({
        gritBatch: {...this.state.gritBatch, deliveryDate : date }
      });
    } else if (this.state.batchType === BatchType.PEAT) {
      this.setState({
        peatBatch: {...this.state.peatBatch, deliveryDate : date }
      });
    }
  }

  private onSelectChange = (e: any, { name, value }: DropdownProps) => {
    this.setState({ batchType: value as BatchType });
  }

  /**
 * Renders dropdown options
 */
  private renderBatchTypeOptions = () => {
    return Object.values(BatchType).map(type => {
      return { text: LocalizedUtils.getLocalizedBatchType(type.toUpperCase()), value: type }
    });
  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
export function mapStateToProps(state: StoreState) {
  return {
    seedBatches: state.seedBatches,
    seedBatch: state.seedBatch,
    treeSpecies: state.treeSpecies,
    facility: state.facility
  };
}

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
export function mapDispatchToProps(dispatch: Dispatch<actions.AppAction>) {
  return {
    onSeedBatchCreated: (seedBatch: SeedBatch) => dispatch(actions.seedBatchCreated(seedBatch)),
    onTreeSpeciesFound: (treeSpecies: TreeSpecies[]) => dispatch(actions.treeSpeciesFound(treeSpecies)),
    onError: (error: ErrorMessage | undefined) => dispatch(actions.onErrorOccurred(error))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePeatGritBatch);