import LocalizedStrings, {
  LocalizedStringsMethods
} from 'localized-strings';

export interface IStrings extends LocalizedStringsMethods {

  errorRetryText: string,
  errorSupportText: string,
  errorRetryHere: string,
  defaultErrorTitle: string,
  defaultErrorMessage: string,
  defaultApiErrorTitle: string,
  defaultApiErrorMessage: string,
  connectionErrorText: string,
  connectionSuccessText: string

  errorSeedBatchMissingQuantitiesMessage: string;

  managementHeaderText1: string;
  managementHeaderText2: string;
  save: string;
  date: string;
  dateRange: string;
  dateAfter: string;
  dateBefore: string;
  selectDate: string;
  open: string;
  savedSuccessfully: string;
  delete: string;

  packageSize: string;
  packingMethod: string;
  newPackageSize: string;
  packageSizeName: string;
  packageSizeSize: string;

  product: string;
  products: string;
  allProducts: string;
  newProduct: string;
  productName: string;
  selectProduct: string;

  newTreeSpecies: string;
  treeSpeciesName: string;
  treeName: string;

  location: string;
  locations: string;
  sourceLocation: string;
  targetLocation: string;
  platforms: string;
  amount: string;
  newLocation: string;
  productionLineNumber: string;
  productionLineDefaultGutterHoleCount: string;
  productionLineNotNumber: string;

  seed: string;
  seeds: string;
  newSeed: string;
  seedName: string;

  seedBatch: string;
  seedBatches: string;
  materials: string;
  newSeedBatch: string;
  seedBatchCode: string;
  seedBatchArrived: string;

  cultivationAction: string;
  performedCultivationAction: string;
  performedCultivationActions: string;
  newPerformedCultivationAction: string;
  performedCultivationActionName: string;
  deletePerformedCultivationAction: string;

  batches: string,
  newBatch: string,
  editBatch: string,
  batchPhase: string,
  batchProduct: string,
  batchNoEvents: string,
  batchPhaseSOWING: string,
  batchPhaseTABLE_SPREAD: string,
  batchPhasePLANTING: string,
  batchPhaseHARVEST: string,
  batchPhaseCOMPLETE: string,

  sowingEventHeader: string
  sowingEventText: string

  tablespreadEventHeader: string
  tablespreadEventText: string

  plantingEventHeader: string
  plantingEventText: string

  observationsEventHeader: string
  luminanceObservationText: string
  weightObservationText: string
  pestObservationText: string


  harvestEventHeader: string
  harvestEventText: string

  wastageEventHeader: string
  wastageEventText: string

  unknownEventHeader: string

  wastageReason: string
  wastageReasons: string
  newWastageReason: string
  wastageReasonReason: string
  deleteConfirmationText: string

  observations: string
  newPest: string
  pestName: string
  cellAmount: string
  labelDriver: string

  labelPackingType: string
  byHand: string
  machine: string
  labelPersonCount: string

  remainingUnitsText: string
  processedUnitsText: string
  batchStatusButtonOPEN: string
  batchStatusButtonCLOSED: string
  batchStatusButtonNEGATIVE: string

  labelPhase: string
  labelProductionLine: string
  labelWastageReason: string
  labelLocation: string
  labelPotType: string
  labelSeedBatch: string
  labelWorkerCount: string
  labelAmount: string
  labelValue: string
  labelLargeTrayAmount: string
  labelBoxAmount: string
  labelGutterHoleCount: string
  labelGutterCount: string
  labelNumberOfBaskets: string
  labelTotalBasketWeight: string
  labelBasketWeights: string
  labelAddBasket: string
  labelTrayCount: string
  labelPackageSize: string
  labelPackedCount: string
  labelHarvestType: string
  labelPests: string
  labelPerformedCultivationActions: string
  labelWeight: string
  labelLuminance: string
  labelStartTime: string
  labelEndTime: string
  labelBatchNumber: string
  labelEventType: string
  labelActionAdditionalInformation: string
  editEventHeader: string
  deleteEventConfirmText: string
  harvestTypeBAGGING: string
  harvestTypeCUTTING: string
  harvestTypeBOXING: string
  potTypeSMALL: string
  potTypeLARGE: string
  potTypePAPER: string
  phasePLANTING: string
  phaseSOWING: string
  phaseTRANSFER: string
  phaseTABLE_SPREAD: string
  phaseWASTAGE: string,
  phaseCULTIVATION_OBSERVATION: string
  phaseLOAD: string
  phaseHARVEST: string
  phasePerformedACTION: string
  phasePACKING: string
  editEventLink: string
  labelAdditionalInformation: string
  goBack: string
  siteHeader: string
  siteSubHeader: string
  peatBatchHeader: string
  gritBatchHeader: string

  //Location types
  ramp: string
  plasticRoom: string
  freezer: string
  melting: string

  labelLocationType: string
  labelLocationName: string
  labelLocationCapacity: string

  reportDownloadHeader: string
  reportTypeLabel: string
  reportTypePlaceholder: string
  reportTypeItemSEED_STORAGE: string
  userManagementLink: string
  accountUrl: string
  logoutUrl: string
  newEvent: string
  addEventHeader: string

  missingRequiredFieldError: string
  logPhaseWastage: string
  logPhaseWastageHeader: string
  deleteBatchConfirmText: string
  deleteBatchButtonText: string
  batchDeletionError: string
  batchDeletionSuccess: string
  batchDeletingInProgress: string

  packings: string
  newPacking: string
  packingStatus: string
  packingStoreStatus: string
  packingWastageStatus: string
  packingRemovedStatus: string
  selectPackingStatus: string
  editPacking: string

  showPassiveSeedBatches: string
  seedBatchState: string

  returnToPreviousList: string

  printPacking: string
  print: string
  selectPrinter: string
  update: string

  amountInStore: string
  oldestPackingInStore: string
  store: string
  openAll: string
  closeAll: string
  filterByDate: string

  timber: string
  allTimber: string
  newTimber: string
  campaignName: string
  productCount: string
  addCampaignProduct: string
  editCampaign: string

  packingType: string
  packingTypeBasic: string
  packingTypeCampaign: string
  campaign: string

  subcontractorProduct: string

  //Container types
  labelContainerType: string
  containerBag: string
  containerCardboard: string
  containerPlastic: string

  cut: string
  weight: string
  gutterCount: string
  gutterHoleCount: string
  contactInformation: string
  producer: string
  sowingDay: string
  cuttingDay: string
  storageCondition: string
  createCutPacking: string
  packingTarget: string

  editCutPacking: string
  events: string
  total: string
  labelSowingDate: string
  allEventTypes: string
  showInActiveProductsLabel: string
  activeProductLabel: string

  packingTableHeaderName: string
  packingTableHeaderDate: string
  packingTableHeaderBoxes: string
  packingTableHeaderStatus: string
  packingTableHeaderPackageSize: string

  allPackingStates: string
  allLocations: string

  totalPackingsRow: string

  treeSpecies: string;
  newDiscard: string;
  discardDate: string;
  editDiscard: string;
  edit: string;

  allCampaings: string;
  removeFromStorageButton: string;
  allowedHarvestTypes: string;

  dashboard: string
  dashboardFormDescription: string;
  dashboardCount: string;

  isEndProduct: string;
  isRawMaterial: string;
  newRawMaterial: string;
  salesWeight: string;
  verificationWeighingWeight: string;
  verificationWeighingTime: string;
  usedBasketProductName: string;
  usedBasketBasketCount: string;
  verificationWeighings: string;
  basketsUsed: string;


  // seed batches
  species: string;
  svCode: string;
  thousandSeedWeight: string;
  reportedGermination: string;
  testedGermination: string;
  baseCertificateNumber: string;
  price: string;
  purity: string;
  containerNumber: string;
  seedIdentifier: string;
  baseMaterialType: string;
  sourceArea: string;
  forestryClass: string;
  quantityOfUnits: string;
  quantityOfKilos: string;
  priceForUnit: string;
  priceForKilo: string;

  // peat/grit batches
  batchType: string;
  batchNumber: string;
  supplier: string;
  deliveryDate: string;
  peat: string;
  grit: string;
  peatBatches: string;
  gritBatches: string;
  createBatch: string;

  // product infos
  boxes: string;
  cells: string;
  bags: string;
  treeSpeciesPlatforms: string;
  newProductInfo: string;
  productInfos: string;
}

const strings: IStrings = new LocalizedStrings({
  en_joroinen: require("./en.json"),
  fi_joroinen: require("./fi.json")
}, {
  customLanguageInterface: () => "fi_joroinen"
});

export default strings;