import * as React from "react";
import Keycloak from 'keycloak-js';
import * as actions from "../../actions";
import { ErrorMessage, StoreState } from "../../types";
import { connect } from "react-redux";
import { Dispatch } from "redux";import Api from "../../api";
import { SeedBatch, TreeSpecies, Facility } from "../../generated/client";
import { Navigate } from 'react-router-dom';
import strings from "../../localization/strings";
import {
  Grid,
  Button,
  Loader,
  Form,
  Input,
  Message,
  Confirm,
  InputOnChangeData,
  DropdownProps
} from "semantic-ui-react";
import { FormContainer } from "../FormContainer";
import LocalizedUtils from "../../localization/localizedutils";

/**
 * Interface representing component properties
 */
interface Props {
  keycloak?: Keycloak;
  seedBatchId: string;
  seedBatch?: SeedBatch;
  facility: Facility;
  onSeedBatchSelected?: (seedBatch: SeedBatch) => void;
  onSeedBatchDeleted?: (seedBatchId: string) => void;
  treeSpecies?: TreeSpecies[];
  onTreeSpeciesFound?: (treeSpecies: TreeSpecies[]) => void;
  onError: (error: ErrorMessage | undefined) => void;
}
/**
 * Interface representing component state
 */
interface State {
  seedBatch?: SeedBatch;
  redirect: boolean;
  saving: boolean;
  messageVisible: boolean;
  treeSpecies: TreeSpecies[];
  open:boolean;
}
/**
 * React component for edit seed batch view
 */
class EditSeedBatch extends React.Component<Props, State> {
  /**
   * Constructor
   *
   * @param props component properties
   */
  constructor(props: Props) {
    super(props);
    this.state = {
      redirect: false,
      seedBatch: undefined,
      saving: false,
      messageVisible: false,
      treeSpecies: [],
      open:false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  /**
   * Component did mount life-sycle method
   */
  public async componentDidMount() {
    const { keycloak, facility, onTreeSpeciesFound, onError, onSeedBatchSelected } = this.props;
    try {
      if (!keycloak) {
        return;
      }
      const seedBatchesService = await Api.getSeedBatchesService(keycloak);
      const treeSpeciesService = await Api.getTreeSpeciesService(keycloak);
      const seedBatch = await seedBatchesService.findSeedBatch({
        seedBatchId: this.props.seedBatchId,
        facility: facility
      });
      onSeedBatchSelected && onSeedBatchSelected(seedBatch);
      this.setState({seedBatch: seedBatch});
      const treeSpecies = await treeSpeciesService.listTreeSpecies({ facility: facility });
      onTreeSpeciesFound && onTreeSpeciesFound(treeSpecies);
      this.setState({treeSpecies: treeSpecies});
    } catch (e: any) {
      Api.handleApiError(e, onError);
    }
  }
  /**
   * Handle form submit
   */
  private async handleSubmit() {
    const { keycloak, facility, onError, onSeedBatchSelected } = this.props;
    try {
      if (!keycloak || !this.state.seedBatch) {
        return;
      }
      if (this.state.seedBatch.quantityOfKilos === undefined || this.state.seedBatch.quantityOfUnits === undefined) {
        onError({
          message: strings.errorSeedBatchMissingQuantitiesMessage,
          title: strings.errorSeedBatchMissingQuantitiesMessage,
        })
      }
      const seedBatchesService = await Api.getSeedBatchesService(keycloak);
      this.setState({saving: true});
      await seedBatchesService.updateSeedBatch({
        seedBatchId: this.state.seedBatch.id!,
        seedBatch: this.state.seedBatch,
        facility: facility
      });
      onSeedBatchSelected && onSeedBatchSelected(this.state.seedBatch);
      this.setState({saving: false});
      this.setState({messageVisible: true});
      setTimeout(() => {
        this.setState({messageVisible: false});
      }, 3000);
    } catch (e: any) {
      Api.handleApiError(e, onError);
    }
  }
  /**
   * Handle seedBatch delete
   */
  private async handleDelete() {
    const { keycloak, facility, onError, onSeedBatchDeleted } = this.props;
    try {
      if (!keycloak || !this.state.seedBatch) {
        return;
      }
      const seedBatchesService = await Api.getSeedBatchesService(keycloak);
      const id = this.state.seedBatch.id || "";
      await seedBatchesService.deleteSeedBatch({
        seedBatchId: id,
        facility: facility
      });
      onSeedBatchDeleted && onSeedBatchDeleted(id!);
      this.setState({redirect: true});
    } catch (e: any) {
      Api.handleApiError(e, onError);
    }
  }
  /**
   * Render edit seedBatch view
   */
  public render() {
    if (!this.props.seedBatch) {
      return (
        <Grid style={{paddingTop: "100px"}} centered>
          <Loader inline active size="medium" />
        </Grid>
      );
    }
    if (this.state.redirect) {
      return <Navigate to="/seedBatches" replace={true} />;
    }
    const treeSpeciesOptions = (this.state.treeSpecies || []).map((tree) => {
      return {
        key: tree.id,
        text: LocalizedUtils.getLocalizedValue(tree.name),
        value: tree.id
      };
    });

    return (
      <Grid>
        <Grid.Row className="content-page-header-row">
          <Grid.Column width={6}>
            <h2>{strings.seedBatch}</h2>
          </Grid.Column>
          <Grid.Column width={3} floated="right">
            <Button className="danger-button" onClick={()=>this.setState({open:true})}>{strings.delete}</Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
          <FormContainer>
          <Form.Field required>
                <label>{strings.species}</label>
                <Form.Select
                  fluid
                  required
                  options={treeSpeciesOptions}
                  onChange={this.onSelectChange}
                  value={this.state.seedBatch?.treeSpeciesId}
                />
              </Form.Field>
              <Form.Field required>
                <label>{strings.svCode}</label>
                <Input
                  value={this.state.seedBatch?.svCode}
                  onChange={(e) => this.setState({seedBatch: {...this.state.seedBatch!, svCode: e.currentTarget.value}})}
                />
              </Form.Field>
              <Form.Field required>
                <label>{strings.thousandSeedWeight}</label>
                <Input
                  type="number"
                  step="0.01"
                  value={this.state.seedBatch?.thousandSeedWeight}
                  onChange={ this.onThousandSeedWeightChange }
                />
              </Form.Field>
              <Form.Field required>
                <label>{strings.reportedGermination}</label>
                <Input
                  type="number"
                  step="0.01"
                  value={this.state.seedBatch?.reportedGermination}
                  onChange={ this.onReportedGerminationChange }
                />
              </Form.Field>
              <Form.Field>
                <label>{strings.testedGermination}</label>
                <Input
                  type="number"
                  step="0.01"
                  value={this.state.seedBatch?.testedGermination}
                  onChange={ this.onTestedGerminationChange }
                />
              </Form.Field>
              <Form.Field required>
                <label>{strings.baseCertificateNumber}</label>
                <Input
                  value={this.state.seedBatch?.baseCertificateNumber}
                  onChange={(e) => this.setState({seedBatch: {...this.state.seedBatch!, baseCertificateNumber: e.currentTarget.value}})}
                />
              </Form.Field>
              <Form.Field>
                <label>{strings.quantityOfKilos}</label>
                <Input
                  type="number"
                  step="0.01"
                  value={this.state.seedBatch?.quantityOfKilos}
                  onChange={ this.onQuantityOfKilosChange }
                />
              </Form.Field>
              <Form.Field>
                <label>{strings.quantityOfUnits}</label>
                <Input
                  type="number"
                  step="1"
                  value={this.state.seedBatch?.quantityOfUnits}
                  onChange={ this.onQuantityOfUnitsChange }
                />
              </Form.Field>
              <Form.Field>
                <label>{strings.priceForKilo}</label>
                <Input
                  type="number"
                  step="0.01"
                  value={this.state.seedBatch?.priceForKilo}
                  onChange={ this.onPriceForKiloChange }
                />
              </Form.Field>
              <Form.Field>
                <label>{strings.priceForUnit}</label>
                <Input
                  type="number"
                  step="0.01"
                  value={this.state.seedBatch?.priceForUnit}
                  onChange={ this.onPriceForUnitChange }
                />
              </Form.Field>
              <Form.Field>
                <label>{strings.purity}</label>
                <Input
                  type="number"
                  step="0.01"
                  value={this.state.seedBatch?.purity}
                  onChange={ this.onPurityChange }
                />
              </Form.Field>
              <Form.Field>
                <label>{strings.containerNumber}</label>
                <Input
                  value={this.state.seedBatch?.containerNumber}
                  onChange={(e) => this.setState({seedBatch: {...this.state.seedBatch!, containerNumber: e.currentTarget.value}})}
                />
              </Form.Field>
              <Form.Field>
                <label>{strings.seedIdentifier}</label>
                <Input
                  value={this.state.seedBatch?.seedIdentifier}
                  onChange={(e) => this.setState({seedBatch: {...this.state.seedBatch!, seedIdentifier: e.currentTarget.value}})}
                />
              </Form.Field>
              <Form.Field>
                <label>{strings.baseMaterialType}</label>
                <Input
                  value={this.state.seedBatch?.baseMaterialType}
                  onChange={(e) => this.setState({seedBatch: {...this.state.seedBatch!, baseMaterialType: e.currentTarget.value}})}
                />
              </Form.Field>
              <Form.Field>
                <label>{strings.sourceArea}</label>
                <Input
                  value={this.state.seedBatch?.sourceArea}
                  onChange={(e) => this.setState({seedBatch: {...this.state.seedBatch!, sourceArea: e.currentTarget.value}})}
                />
              </Form.Field>
              <Form.Field>
                <label>{strings.forestryClass}</label>
                <Input
                  value={this.state.seedBatch?.forestryClass}
                  onChange={(e) => this.setState({seedBatch: {...this.state.seedBatch!, forestryClass: e.currentTarget.value}})}
                />
              </Form.Field>
            <Message
              success
              visible={this.state.messageVisible}
              header={strings.savedSuccessfully}
            />
            <Button
              className="submit-button"
              onClick={this.handleSubmit}
              type='submit'
              loading={this.state.saving}
            >
                {strings.save}
            </Button>
          </FormContainer>
          </Grid.Column>
        </Grid.Row>
        <Confirm open={this.state.open} size={"mini"} content={strings.deleteConfirmationText +this.props.seedBatch!.svCode } onCancel={()=>this.setState({open:false})} onConfirm={this.handleDelete} />
      </Grid>
    );
  }

  /**
   * Handle select change
   *
   * @param e event
   * @param {value} value
   */
  onSelectChange = (e: any, { value }: DropdownProps) => {
    this.setState({seedBatch: {...this.state.seedBatch!, treeSpeciesId: value as string}})
  }

  /**
   * Handles changing onThousandSeedWeightChange
   */
  private onThousandSeedWeightChange = async (e: any, { value }: InputOnChangeData) => {
    this.setState({seedBatch: {...this.state.seedBatch!, thousandSeedWeight: Number.parseFloat(value)}})

  }

  /**
   * Handles changing onReportedGerminationChange
   */
  private onReportedGerminationChange = async (e: any, { value }: InputOnChangeData) => {
    this.setState({seedBatch: {...this.state.seedBatch!, reportedGermination: Number.parseFloat(value)}})
  }

  /**
   * Handles changing onTestedGerminationChange
   */
  private onTestedGerminationChange = async (e: any, { value }: InputOnChangeData) => {
    this.setState({seedBatch: {...this.state.seedBatch!, testedGermination: Number.parseFloat(value)}})
  }

  /**
   * Handles changing onQuantityOfUnitsChange
   */
  private onQuantityOfUnitsChange = async (e: any, { value }: InputOnChangeData) => {
    this.setState({seedBatch: {...this.state.seedBatch!, quantityOfUnits: Number.parseInt(value)}})
  }

  /**
   * Handles changing onQuantityOfKilosChange
   */
  private onQuantityOfKilosChange = async (e: any, { value }: InputOnChangeData) => {
    this.setState({seedBatch: {...this.state.seedBatch!, quantityOfKilos: Number.parseFloat(value)}})
  }

  /**
   * Handles changing onPriceForUnitChange
   */
  private onPriceForUnitChange = async (e: any, { value }: InputOnChangeData) => {
    this.setState({seedBatch: {...this.state.seedBatch!, priceForUnit: Number.parseFloat(value)}})
  }

  /**
   * Handles changing onPriceForKiloChange
   */
  private onPriceForKiloChange = async (e: any, { value }: InputOnChangeData) => {
    this.setState({seedBatch: {...this.state.seedBatch!, priceForKilo: Number.parseFloat(value)}})
  }

  /**
   * Handles changing onPurityChange
   */
  private onPurityChange = async (e: any, { value }: InputOnChangeData) => {
    this.setState({seedBatch: {...this.state.seedBatch!, purity: Number.parseFloat(value)}})
  }

}/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
export function mapStateToProps(state: StoreState) {
  return {
    seedBatches: state.seedBatches,
    seedBatch: state.seedBatch,
    treeSpecies: state.treeSpecies,
    facility: state.facility
  };
}/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
export function mapDispatchToProps(dispatch: Dispatch<actions.AppAction>) {
  return {
    onSeedBatchSelected: (seedBatch: SeedBatch) => dispatch(actions.seedBatchSelected(seedBatch)),
    onSeedBatchDeleted: (seedBatchId: string) => dispatch(actions.seedBatchDeleted(seedBatchId)),
    onTreeSpeciesFound: (treeSpecies: TreeSpecies[]) => dispatch(actions.treeSpeciesFound(treeSpecies)),
    onError: (error: ErrorMessage | undefined) => dispatch(actions.onErrorOccurred(error))
  };
}export default connect(mapStateToProps, mapDispatchToProps)(EditSeedBatch);