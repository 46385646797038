import * as React from "react";
import Keycloak from 'keycloak-js';
import * as actions from "../../actions";
import { ErrorMessage, StoreState } from "../../types";
import { connect } from "react-redux";
import { Dispatch } from "redux";import Api from "../../api";
import { NavLink } from 'react-router-dom';
import { Facility, GritBatch, PeatBatch } from "../../generated/client";
import strings from "../../localization/strings";
import { Grid, Button, Loader, Table, Visibility } from "semantic-ui-react";
import moment from "moment";

export interface Props {
  keycloak?: Keycloak;
  gritBatches?: GritBatch[];
  peatBatches?: PeatBatch[];
  facility: Facility;
  onPeatBatchesFound?: (peatBatches: PeatBatch[]) => void;
  onGritBatchesFound?: (gritBatches: GritBatch[]) => void;
  onError: (error: ErrorMessage | undefined) => void;
}

export interface State {
  peatBatches: PeatBatch[];
  gritBatches: GritBatch[];
}

/**
 * List of peat and grit batches
 */
class PeatGritBatchList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      peatBatches: [],
      gritBatches: []
    };
  }

  /**
   * Component did mount life-sycle method
   */
  public async componentDidMount() {
    const { keycloak, facility, onError, onPeatBatchesFound, onGritBatchesFound } = this.props;
    try {
      if (!keycloak) {
        return;
      }

      const peatBatchesService = await Api.getPeatBatchesService(keycloak);
      const gritBatchesService = await Api.getGritBatchesService(keycloak);

      const peatBatches = await peatBatchesService.listPeatBatches({facility: facility});
      const gritBatches = await gritBatchesService.listGritBatches({facility: facility});

      this.setState({gritBatches: gritBatches, peatBatches: peatBatches});
      onGritBatchesFound && onGritBatchesFound(gritBatches);
      onPeatBatchesFound && onPeatBatchesFound(peatBatches);
    } catch (e: any) {
      Api.handleApiError(e, onError);
    }
  }

  /**
   * Renders peat batch row
   * @param peatBatch peat batches
   * @returns row
   */
  private renderPeatBatchRow = (peatBatch: PeatBatch) => {
    return (
      <Table.Row key={ peatBatch.id }>
        <Table.Cell>{ peatBatch.amount }</Table.Cell>
        <Table.Cell>{ peatBatch.batchNumber }</Table.Cell>
        <Table.Cell>{ moment(peatBatch.deliveryDate.getTime()).format("DD.MM.YYYY") }</Table.Cell>
        <Table.Cell>{ peatBatch.supplier }</Table.Cell>
        <Table.Cell textAlign='right'>
          <NavLink to={`/peatBatches/${peatBatch.id}`}>
              <Button className="submit-button">{strings.open}</Button>
          </NavLink>
        </Table.Cell>
      </Table.Row>
    )
  }

  /**
   * Renders grit batch row
   * @param gritBatch grit batches
   * @returns row
   */
  private renderGritBatchRow = (gritBatch: GritBatch) => {
    return (
      <Table.Row key={ gritBatch.id }>
        <Table.Cell>{ gritBatch.amount }</Table.Cell>
        <Table.Cell>{ gritBatch.batchNumber }</Table.Cell>
        <Table.Cell>{ moment(gritBatch.deliveryDate.getTime()).format("DD.MM.YYYY") }</Table.Cell>
        <Table.Cell>{ gritBatch.supplier }</Table.Cell>
        <Table.Cell textAlign='right'>
          <NavLink to={`/gritBatches/${gritBatch.id}`}>
              <Button className="submit-button">{strings.open}</Button>
          </NavLink>
        </Table.Cell>
      </Table.Row>
    )
  }

  /**
   * Render package size list view
   */
  render() {
    if (!this.props.gritBatches || !this.props.peatBatches) {
      return (
        <Grid style={{paddingTop: "100px"}} centered>
          <Loader inline active size="medium" />
        </Grid>
      );
    }

    const peatBatchRows = this.state.peatBatches!.map((peatBatch, i) => this.renderPeatBatchRow(peatBatch));
    const gritBatchRows = this.state.gritBatches!.map((gritBatch, i) => this.renderGritBatchRow(gritBatch));

    return (
      <Grid>
        <Grid.Row className="content-page-header-row" style={{flex: 1,justifyContent: "space-between", paddingLeft: 10, paddingRight: 10}}>
          <h2>{ strings.peatBatches } </h2>
          <NavLink to="/createPeatGritBatch">
            <Button className="submit-button">{ strings.createBatch }</Button>
          </NavLink>
        </Grid.Row>
        <Grid.Row>
        <Grid.Column>
        <Visibility>
        <div className="scrollable-table">
          <Table selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{ strings.amount }</Table.HeaderCell>
                <Table.HeaderCell>{ strings.batchNumber }</Table.HeaderCell>
                <Table.HeaderCell>{ strings.deliveryDate }</Table.HeaderCell>
                <Table.HeaderCell>{ strings.supplier }</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { peatBatchRows }
            </Table.Body>
          </Table>
          </div>
        </Visibility>
        </Grid.Column>
      </Grid.Row>
      <h2>{ strings.gritBatches } </h2>
      <Grid.Row>
        <Grid.Column>
        <Visibility>
        <div className="scrollable-table">
          <Table selectable>
          <Table.Header>
              <Table.Row>
              <Table.HeaderCell>{ strings.amount }</Table.HeaderCell>
                <Table.HeaderCell>{ strings.batchNumber }</Table.HeaderCell>
                <Table.HeaderCell>{ strings.deliveryDate }</Table.HeaderCell>
                <Table.HeaderCell>{ strings.supplier }</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              { gritBatchRows }
            </Table.Body>
          </Table>
          </div>
        </Visibility>
        </Grid.Column>
      </Grid.Row>
      </Grid>
    );

  }
}

/**
 * Redux mapper for mapping store state to component props
 *
 * @param state store state
 */
export function mapStateToProps(state: StoreState) {
  return {
    peatBatches: state.peatBatches,
    gritBatches: state.gritBatches,
    facility: state.facility
  };
}

/**
 * Redux mapper for mapping component dispatches
 *
 * @param dispatch dispatch method
 */
export function mapDispatchToProps(dispatch: Dispatch<actions.AppAction>) {
  return {
    onPeatBatchesFound: (peatBatches: PeatBatch[]) => dispatch(actions.peatBatchesFound(peatBatches)),
    onGritBatchesFound: (gritBatches: GritBatch[]) => dispatch(actions.gritBatchesFound(gritBatches)),
    onError: (error: ErrorMessage | undefined) => dispatch(actions.onErrorOccurred(error))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PeatGritBatchList);