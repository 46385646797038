import Keycloak from 'keycloak-js';
import {
  CampaignsApi,
  Configuration,
  CutPackingsApi,
  DraftsApi,
  EventsApi,
  PackageSizesApi,
  CultivationActionsApi,
  PestsApi,
  PrintersApi,
  LocationsApi,
  ProductsApi,
  ReportsApi,
  BatchesApi,
  SeedBatchesApi,
  WastageReasonsApi,
  TreeSpeciesApi,
  StorageDiscardsApi,
  PeatBatchesApi,
  GritBatchesApi,
  ProductInfoApi} from '../generated/client';
import { ErrorMessage } from '../types';
import strings from '../localization/strings';

const API_URL = process.env.REACT_APP_UPM_API_BASE_PATH || "http://localhost:8080";

/**
 * Class for handling communications with API
 */
export class Api {

  /**
   * Handles response from API
   *
   * @param response response object
   */
  public static handleResponse(response: any, empty200?: boolean) {
    switch (response.status) {
      case 204:
        return {};
      default:
        if (empty200) {
          return {};
        }
        return response.json();
    }
  }

  /**
   * Handles API errors
   *
   * @param error error object
   * @param onError callback for error handling
   */
  public async handleApiError(
    error: any,
    onError: (error: ErrorMessage | undefined) => void
  ) {
    console.error(error);

    if (error instanceof Response) {
      const errorText = await error.text();
      let errorMessage = strings.defaultApiErrorMessage;

      try {
        const parsedError = JSON.parse(errorText);
        errorMessage = parsedError.message || errorMessage;
      } catch (e) {
        console.error("Failed to parse error response as JSON:", e);
        errorMessage = errorText || strings.defaultApiErrorMessage
      } finally {
        console.error(`Error status: ${error.status}`);
        console.error(`Error message: ${errorMessage}`);

        onError({
          message: errorMessage,
          title: String(error.status),
          exception: error as any,
        });
      }
    } else {
      onError({
        message: strings.defaultApiErrorMessage,
        title: strings.defaultApiErrorTitle,
        exception: error,
      });
    }
  }

  /**
   * Returns a promise of tree species service authenticated with a valid token
   *
   * @param keycloak keycloak instance
   * @returns a promise of tree species service authenticated with a valid token
   */
  public async getTreeSpeciesService(keycloak: Keycloak): Promise<TreeSpeciesApi> {
    return new TreeSpeciesApi(await this.getApiConfiguration(keycloak));
  }

  /**
   * Returns a promise of batches service authenticated with a valid token
   *
   * @param keycloak keycloak instance
   * @returns a promise of batches service authenticated with a valid token
   */
  public async getBatchesService(keycloak: Keycloak): Promise<BatchesApi> {
    return new BatchesApi(await this.getApiConfiguration(keycloak));
  }

 /**
   * Returns a promise for cut packings service authenticated with a valid token
   *
   * @param keycloak  keycloak instance
   * @returns a promise for cut packings service authenticated with a valid token
   */
  public async getCutPackingsService(keycloak: Keycloak): Promise<CutPackingsApi> {
    return new CutPackingsApi(await this.getApiConfiguration(keycloak));
  }

  /**
   * Returns a promise for campaigns service authenticated with a valid token
   *
   * @param keycloak  keycloak instance
   * @returns a promise for campaigns service authenticated with a valid token
   */
  public async getCampaignsService(keycloak: Keycloak): Promise<CampaignsApi> {
    return new CampaignsApi(await this.getApiConfiguration(keycloak));
  }

  /**
   * Returns a promise of printers service authenticated with a valid token
   *
   * @param keycloak keycloak instance
   * @returns a promise of printers service authenticated with a valid token
   */
  public async getPrintersService(keycloak: Keycloak): Promise<PrintersApi> {
    return new PrintersApi(await this.getApiConfiguration(keycloak));
  }

  /**
   * Returns promise of events service authenticated with valid token
   *
   * @param keycloak keycloak instance
   * @returns promise of events service authenticated with valid token
   */
  public async getEventsService(keycloak: Keycloak): Promise<EventsApi> {
    return new EventsApi(await this.getApiConfiguration(keycloak));
  }

  /**
   * Returns promise of package sizes service authenticated with valid token
   *
   * @param keycloak keycloak instance
   * @returns promise of package sizes service authenticated with valid token
   */
  public async getPackageSizesService(keycloak: Keycloak): Promise<PackageSizesApi> {
    return new PackageSizesApi(await this.getApiConfiguration(keycloak));
  }

  /**
   * Returns promise of performed cultivation actions service authenticated with valid token
   *
   * @param keycloak keycloak instance
   * @returns promise of performed cultivation actions service authenticated with valid token
   */
  public async getPerformedCultivationActionsService(keycloak: Keycloak): Promise<CultivationActionsApi> {
    return new CultivationActionsApi(await this.getApiConfiguration(keycloak));
  }

  /**
   * Returns promise of locations service authenticated with valid token
   *
   * @param keycloak keycloak instance
   * @returns promise of locations service authenticated with valid token
   */
  public async getLocationsService(keycloak: Keycloak): Promise<LocationsApi> {
    return new LocationsApi(await this.getApiConfiguration(keycloak));
  }

  /**
   * Returns promise of products service authenticated with valid token
   *
   * @param keycloak keycloak instance
   * @returns promise of products service authenticated with valid token
   */
  public async getProductsService(keycloak: Keycloak): Promise<ProductsApi> {
    return new ProductsApi(await this.getApiConfiguration(keycloak));
  }

  /**
   * Returns promise of product info service authenticated with valid token
   *
   * @param keycloak keycloak instance
   * @returns promise of product info service authenticated with valid token
   */
  public async getProductInfoService(keycloak: Keycloak): Promise<ProductInfoApi> {
    return new ProductInfoApi(await this.getApiConfiguration(keycloak));
  }

  /**
   * Returns promise of reports service authenticated with valid token
   *
   * @param keycloak keycloak instance
   * @returns promise of reports service authenticated with valid token
   */
  public async getReportsService(keycloak: Keycloak): Promise<ReportsApi> {
    return new ReportsApi(await this.getApiConfiguration(keycloak));
  }

  /**
   * Returns promise of seed batches service authenticated with valid token
   *
   * @param keycloak keycloak instance
   * @returns promise of seed batches service authenticated with valid token
   */
  public async getSeedBatchesService(keycloak: Keycloak): Promise<SeedBatchesApi> {
    return new SeedBatchesApi(await this.getApiConfiguration(keycloak));
  }

  /**
   * Returns promise of seed batches service authenticated with valid token
   *
   * @param keycloak keycloak instance
   * @returns promise of seed batches service authenticated with valid token
   */
  public async getSeedBatchService(keycloak: Keycloak): Promise<SeedBatchesApi> {
    return new SeedBatchesApi(await this.getApiConfiguration(keycloak));
  }

  /**
   * Returns promise of wastage reasons service authenticated with valid token
   *
   * @param keycloak keycloak instance
   * @returns promise of wastage reasons service authenticated with valid token
   */
  public async getWastageReasonsService(keycloak: Keycloak): Promise<WastageReasonsApi> {
    return new WastageReasonsApi(await this.getApiConfiguration(keycloak));
  }

    /**
   * Returns promise of drafts service authenticated with valid token
   *
   * @param keycloak keycloak instance
   * @returns promise of drafts service authenticated with valid token
   */
  public async getDraftsService(keycloak: Keycloak): Promise<DraftsApi> {
    return new DraftsApi(await this.getApiConfiguration(keycloak));
  }

  /**
   * Returns promise of pests service authenticated with valid token
   *
   * @param keycloak keycloak instance
   * @returns promise of pests service authenticated with valid token
   */
  public async getPestsService(keycloak: Keycloak): Promise<PestsApi> {
    return new PestsApi(await this.getApiConfiguration(keycloak));
  }

  /**
   * Returns promise of peat bacthes service authenticated with valid token
   *
   * @param keycloak keycloak instance
   * @returns promise of peat bacthes service authenticated with valid token
   */
  public async getPeatBatchesService(keycloak: Keycloak): Promise<PeatBatchesApi> {
    return new PeatBatchesApi(await this.getApiConfiguration(keycloak));
  }

  /**
   * Returns promise of grit batches service authenticated with valid token
   *
   * @param keycloak keycloak instance
   * @returns promise of grit batches service authenticated with valid token
   */
  public async getGritBatchesService(keycloak: Keycloak): Promise<GritBatchesApi> {
    return new GritBatchesApi(await this.getApiConfiguration(keycloak));
  }

  /**
   * Returns promise of storage discards service authenticated with valid token
   *
   * @param keycloak keycloak instance
   * @returns promise of storage discards service authenticated with valid token
   */
  public async getStorageDiscardsService(keycloak: Keycloak): Promise<StorageDiscardsApi> {
    return new StorageDiscardsApi(await this.getApiConfiguration(keycloak));
  }

  private getApiConfiguration = async (keycloak: Keycloak): Promise<Configuration> => {
    return new Configuration({
      basePath: API_URL,
      accessToken: await this.checkTokenValidity(keycloak)
    });
  }

  /**
   * Check validation of given keycloak's token
   *
   * @param keycloak
   */
  private async checkTokenValidity(keycloak: Keycloak): Promise<string> {
    await keycloak.updateToken(5);

    if (!keycloak.token) {
      throw new Error("Token is empty");
    }

    return keycloak.token;
  }

}

const apiInstance = new Api();
export default apiInstance;
